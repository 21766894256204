<template>
  <div style="padding: 16px">
    <!-- 头部部分 -->
    <div>
      <el-row>
        <el-form>
          <el-col :span="6">
            <el-form-item label="到期时间" label-width="80px">
              <el-date-picker v-model="overDate" type="daterange" range-separator="至" start-placeholder="开始日期"
                value-format="yyyy-MM-dd" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="用户名称" label-width="80px">
              <el-input v-model="queryInfo.condition.memberName" placeholder="请输入用户名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="用户手机号" label-width="90px">
              <el-input v-model="queryInfo.condition.phone" placeholder="请输入用户手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-button type="primary" class="ml10" @click="search">
            搜 索
          </el-button>
          <el-button @click="reset"> 重 置 </el-button>
        </el-form>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-button type="primary" @click="newBtn">新建</el-button>
          <el-button type="primary" @click="exportExcel">导出</el-button>
        </el-col>
        <!-- <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.condition.queryKey"
            class="input-with-select"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col> -->
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%" height="73vh">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>

        <!-- <el-table-column label="id" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.id }}</template>
</el-table-column>
<el-table-column label="用户编码" align="center" show-overflow-tooltip>
  <template slot-scope="scope">{{ scope.row.memberCode }}</template>
</el-table-column> -->
        <el-table-column label="用户名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.memberName }}</template>
        </el-table-column>
        <el-table-column label="用户类型" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.userType == "1" ? "门店账号" : "员工账号"
          }}</template>
        </el-table-column>
        <!-- <el-table-column label="登录名" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.loginName }}</template>
        </el-table-column>
        <el-table-column
          label="密码MD5加密"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.pwd }}</template>
        </el-table-column>
        <el-table-column
          label="微信小程序openId"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.openId }}</template>
        </el-table-column> -->
        <el-table-column label="手机号" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.phone }}</template>
        </el-table-column>
        <el-table-column label="公司名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.companyName }}</template>
        </el-table-column>
        <!-- <el-table-column label="头像" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.logo }}</template>
        </el-table-column>
        <el-table-column label="性别" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.sex }}</template>
        </el-table-column>
        <el-table-column label="上级编码" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.parentCode }}</template>
        </el-table-column>
        <el-table-column label="出生年月" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.birthday }}</template>
        </el-table-column> -->
        <el-table-column label="地址" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.address }}</template>
        </el-table-column>
        <!-- <el-table-column label="省级编码" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.provinceCode }}</template>
        </el-table-column> -->
        <el-table-column label="省级名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.provinceName }}</template>
        </el-table-column>
        <!-- <el-table-column label="市级编码" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.cityCode }}</template>
        </el-table-column> -->
        <el-table-column label="市级名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.cityName }}</template>
        </el-table-column>
        <!-- <el-table-column label="区级编码" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.areaCode }}</template>
        </el-table-column> -->
        <el-table-column label="区级名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.areaName }}</template>
        </el-table-column>
        <el-table-column label="状态" align="center" show-overflow-tooltip width="125px;">
          <template slot-scope="scope">
            <p>{{ scope.row.isDisable == "0" ? "启动中：" : "已停用：" }}</p>
            <el-switch v-model="scope.row.isDisable" active-color="#13ce66" inactive-color="#ff4949" active-value="0"
              inactive-value="1" @change="switchChange(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createDate }}</template>
        </el-table-column>
        <el-table-column label="截止时间" align="center">
          <template slot-scope="scope">{{
            scope.row.subCutoffDate ? scope.row.subCutoffDate : "-"
          }}</template>
        </el-table-column>
        <el-table-column label="备注" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.remarks }}</template>
        </el-table-column>

        <!-- <el-table-column
          label="账户有效类型(1:截止日期;2:有效时间)"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.effectiveType }}</template>
        </el-table-column>
        <el-table-column
          label="子账户有效时间"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.subCutoffDate }}</template>
        </el-table-column> -->
        <el-table-column label="操作" width="180" align="center" fixed="right">
          <template slot-scope="scope" v-if="scope.row.userType == '1'">
            <el-button type="text" @click="edit(scope.row)" size="small" style="padding: 0 5px">编辑</el-button>
            <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.memberCode)">
              <el-button slot="reference" type="text" style="padding: 0 5px">删除</el-button>
            </el-popconfirm>
            <el-button type="text" @click="showMemberInfo(scope.row)" size="small"
              style="padding: 0 5px">用户信息</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[10, 20, 30, 40]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="labelType == 'add' ? '新增' : '编辑'" :visible.sync="showDialog" width="800px">
      <el-form ref="formData" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">
          <!-- <el-col :span="12">
              <el-form-item label="id：" prop="siteName">
                <el-input v-model="form.id" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户编码：" prop="siteName">
                <el-input v-model="form.memberCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
          <el-col :span="12">
            <el-form-item label="用户名称：" prop="siteName">
              <el-input v-model="form.memberName" placeholder="请输入用户名"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
              <el-form-item label="登录名：" prop="siteName">
                <el-input v-model="form.loginName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信小程序openId：" prop="siteName">
                <el-input v-model="form.openId" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
          <el-col :span="12">
            <el-form-item label="手机号：" prop="siteName">
              <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
              <el-form-item label="头像：" prop="siteName">
                <el-input v-model="form.logo" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别：" prop="siteName">
                <el-input v-model="form.sex" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上级编码：" prop="siteName">
                <el-input v-model="form.parentCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出生年月：" prop="siteName">
                <el-input v-model="form.birthday" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
          <el-col :span="12">
            <el-form-item label="城市：" prop="siteName">
              <el-cascader v-model="form.codeList" placeholder="请选择省市区" :options="options" @change="handleChange"
                style="width: 100%"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地址：" prop="siteName">
              <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司名称：" prop="companyName">
              <el-input v-model="form.companyName" placeholder="请输入公司名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司简介：" prop="companyIntroduce">
              <el-input type="textarea" v-model="form.companyIntroduce" placeholder="请输入公司简介"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="地址：" prop="siteName">
              <el-input
                v-model="form.trueName"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
              <el-form-item label="省级编码：" prop="siteName">
                <el-input v-model="form.provinceCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="省级名称：" prop="siteName">
                <el-input v-model="form.provinceName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="市级编码：" prop="siteName">
                <el-input v-model="form.cityCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="市级名称：" prop="siteName">
                <el-input v-model="form.cityName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="区级编码：" prop="siteName">
                <el-input v-model="form.areaCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="区级名称：" prop="siteName">
                <el-input v-model="form.areaName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
          <!-- <el-col :span="24">
            <el-form-item label="图库：" prop="siteName">
              <el-checkbox
                :indeterminate="form.isIndeterPictes"
                v-model="form.checkAll"
                @change="handleCheckAllChange"
              ></el-checkbox>
              <el-checkbox-group
                v-model="form.checkedPictes"
                @change="handleCheckedPictesChange"
              >
                <el-checkbox
                  v-for="(item, index) in form.pictes"
                  :label="item"
                  :key="index"
                  >{{ item }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="产品分类：" prop="siteName">
              <!-- show-checkbox：节点是否可被选择；highlight-current：是否高亮当前选中节点 -->
              <el-tree v-model="form.relationList" :data="data" :show-checkbox="true" ref="tree" node-key="categoryCode"
                :props="defaultProps" :default-checked-keys="form.checked" @check-change="handleNodeClick">
              </el-tree>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="真实姓名：" prop="siteName">
              <el-input
                v-model="form.trueName"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄：" prop="siteName">
              <el-input v-model="form.age" placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
              <el-form-item label="经度：" prop="siteName">
                <el-input v-model="form.lng" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纬度：" prop="siteName">
                <el-input v-model="form.lat" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间：" prop="siteName">
                <el-input v-model="form.createDate" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="修改时间：" prop="siteName">
                <el-input v-model="form.updateDate" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
          <el-col :span="12">
            <el-form-item label="账号：" prop="siteName">
              <el-input v-model="form.loginName" placeholder="请输入账号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="密码：" prop="siteName">
              <el-input type="password" placeholder="请输入密码" v-model="form.pwd" show-password></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
              <el-form-item label="用户类型：" prop="siteName">
                <el-input v-model="form.userType" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
          <el-col :span="12">
            <el-form-item label="截止时间：">
              <el-date-picker v-model="form.subAccountConfig.cutoffDate" type="date" format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号数量：" prop="siteName">
              <el-input type="number" v-model="form.subAccountConfig.cutoffSubaccountNum"
                placeholder="请输入数量"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期（月）：" prop="siteName">
              <el-input type="number" v-model="form.subAccountConfig.effectiveDuration" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量：" prop="siteName">
              <el-input type="number" v-model="form.subAccountConfig.effectiveSubaccountNum"
                placeholder="请输入数量"></el-input>
            </el-form-item>
          </el-col>
          <!-- 地图 -->
          <!-- <el-col>
            <div id="map" @click="mouseClick"> </div>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="备注：" prop="siteName">
              <el-input v-model="form.remarks" :rows="2" type="textarea" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="closeForm">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>

    <!-- 用户信息 -->
    <el-dialog :title="memberInfo.memberName + '点变家账户信息'" :visible.sync="memberInfoDialog" width="800px" center>
      <el-form ref="formData" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="用户名称：" prop="siteName">
              <div class="memberInfoItem">
                {{ form.memberName || "无" }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号：" prop="siteName">
              <div class="memberInfoItem">
                {{ form.phone || "无" }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地址：" prop="siteName">
              <div class="memberInfoItem">
                {{ form.address || "无" }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司名称：" prop="companyName">
              <div class="memberInfoItem">
                {{ form.companyName || "无" }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号：" prop="siteName">
              <div class="memberInfoItem">
                {{ form.loginName || "无" }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="密码：" prop="siteName">
              <div class="memberInfoItem">
                {{ form.pwd || "无" }}
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="截止时间：">
              <div class="memberInfoItem">
                {{ form.subAccountConfig?.cutoffDate || "无" }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号数量：" prop="siteName">
              <div class="memberInfoItem">
                {{ form.subAccountConfig?.cutoffSubaccountNum || "无" }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期（月）：" prop="siteName">
              <div class="memberInfoItem">
                {{ form.subAccountConfig?.effectiveDuration || "无" }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量：" prop="siteName">
              <div class="memberInfoItem">
                {{
                  form.subAccountConfig?.effectiveSubaccountNum || "无"
                }}
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
// let map, marker, polygon, drawingManager, lngLat,ap;
export default {
  data() {
    return {
      overDate: [], //到期时间
      labelType: "add",
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {},
      },
      form: {
        memberName: "", // 用户名称
        phone: "", // 手机号
        provinceCode: "",
        provinceName: "",
        areaCode: "",
        areaName: "",
        cityCode: "",
        cityName: "",
        pwd: "",
        codeList: [], //省市区
        // trueName: "", // 真实姓名
        address: "", // 地址
        remarks: "", // 备注
        loginName: "", // 账号
        categoryCode: "", // 产品分类
        relationList: [], // 默认选中的节点
        isDisable: "", // 0否 1是
        subAccountConfig: {
          cutoffDate: "", // 子账号截止日期
          cutoffSubaccountNum: "", // 子账号数量
          effectiveDuration: "", // 有效期时长
          effectiveSubaccountNum: "", // 有效期子账号数量
        },
      },
      options: regionData, // 城市
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 200, // 表格高度
      headerHeight: 0,
      data: [],
      defaultProps: {
        children: "children",
        label: "categoryName",
      },
      memberInfoDialog: false,
      memberInfo: {},

      // lng:'117.18420',  // 经度
      // lat:'31.84669',   // 纬度
      // city:[]getHeight
    };
  },

  created() {
    this.getList();
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      // this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    //搜索
    search() {
      this.queryInfo.condition.startTime = this.overDate[0];
      this.queryInfo.condition.endTime = this.overDate[1];
      this.getList();
    },
    //重置
    reset() {
      this.overDate = [];
      this.queryInfo.condition = {};
      this.getList();
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 256;
    },

    // 获取数据
    getList() {
      var that = this;
      that.$http.post("/member/list", that.queryInfo).then(function (response) {
        // console.log(response.data.data);
        if (response.data.code == 200) {
          // that.$notify.success({
          //   title: "提示",
          //   message: "获取数据成功",
          //   showClose: true,
          // });
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage;
          that.tableData = response.data.data.data;
        } else {
          that.$notify.info({
            title: "提示",
            message: response.data.message,
            showClose: true,
          });
        }
      });
    },

    // 保存
    handleSave() {
      var that = this;
      var formData = JSON.parse(JSON.stringify(that.form));
      formData.oldPwd = formData.pwd;
      formData.pwd = that.aes_encrypt(formData.pwd);
      that.$http
        .post("/member/save", formData)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            that.showDialog = false;
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) { });
      // console.log(this.form.subAccountConfig);
      // that.form.pwd = newPwd;
    },

    // 修改
    edit(row) {
      // console.log(row);
      console.log("row", row);

      var that = this;
      that.showDialog = true;
      that.labelType = "edit";
      that.getProduct();
      // let obj = structuredClone(row)
      that.form = JSON.parse(JSON.stringify(row));
      that.$http.post("/member/queryDetail", row).then(function (response) {
        if (response.data.code == 200) {
          console.log(response);
          that.form.provinceCode = response.data.data.codeList[0];
          that.form.cityCode = response.data.data.codeList[1];
          that.form.areaCode = response.data.data.codeList[2];
          that.form.codeList = [
            TextToCode[row.provinceName].code,
            TextToCode[row.provinceName][row.cityName].code,
            TextToCode[row.provinceName][row.cityName][row.areaName].code,
          ];
          that.form.subAccountConfig = response.data.data.subAccountConfig;
          that.form.checked = response.data.data.selectKeys;
          that.form.pwd = that.aes_decrypt(response.data.data.pwd);
          that.$nextTick(() => {
            // that.$refs.tree.setCheckedKeys(response.data.data.selectKeys);
            that.handleNodeClick();
          });
        } else {
          that.$notify.info({
            title: "提示",
            message: response.data.message,
            showClose: true,
          });
        }
      });
    },

    // 删除当前行
    //    handleDelte (id) {
    //      del({ id: id }).then(res => {
    //        if (res.code == 200) {
    //         this.$message.success('删除成功')
    //         this.getList()
    //       }
    //      })
    //    },

    //显示用户信息弹窗
    showMemberInfo(row) {
      console.log("row", row);
      // this.memberInfo = row;
      this.memberInfoDialog = true;
      var that = this;

      that.getProduct();
      // let obj = structuredClone(row)
      that.form = JSON.parse(JSON.stringify(row));
      that.$http.post("/member/queryDetail", row).then(function (response) {
        if (response.data.code == 200) {
          console.log(response);
          that.form.provinceCode = response.data.data.codeList[0];
          that.form.cityCode = response.data.data.codeList[1];
          that.form.areaCode = response.data.data.codeList[2];
          that.form.codeList = [
            TextToCode[row.provinceName].code,
            TextToCode[row.provinceName][row.cityName].code,
            TextToCode[row.provinceName][row.cityName][row.areaName].code,
          ];
          that.form.subAccountConfig = response.data.data.subAccountConfig;
          that.form.checked = response.data.data.selectKeys;
          that.form.pwd = that.aes_decrypt(response.data.data.pwd);

        } else {
          that.$notify.info({
            title: "提示",
            message: response.data.message,
            showClose: true,
          });
        }
      });
    },

    // 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/member/delete", { memberCode: id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true,
              });
              that.getList();
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      }
    },

    // 新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {
        memberName: "", // 用户名称
        phone: "", // 手机号
        provinceCode: "",
        provinceName: "",
        areaCode: "",
        areaName: "",
        cityCode: "",
        cityName: "",
        codeList: [], //省市区
        // trueName: "", // 真实姓名
        address: "", // 地址
        remarks: "", // 备注
        loginName: "", // 账号
        categoryCode: "", // 产品分类
        relationList: [], // 默认选中的节点
        subAccountConfig: {
          cutoffDate: "", // 子账号截止日期
          cutoffSubaccountNum: "", // 子账号数量
          effectiveDuration: "", // 有效期时长
          effectiveSubaccountNum: "", // 有效期子账号数量
        },
      };
      // this.$nextTick(() => {
      this.getProduct();
      // this.$refs.tree.setCheckedKeys([]);
      this.form.checked = [];
      // })
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },

    // 关闭表单
    closeForm() {
      this.showDialog = false;
      this.$nextTick(() => {
        this.form = {};
      });
    },

    // 选择城市
    handleChange(val) {
      this.form.provinceCode = val[0];
      this.form.cityCode = val[1];
      this.form.areaCode = val[2];
      this.form.provinceName = CodeToText[val[0]];
      this.form.cityName = CodeToText[val[1]];
      this.form.areaName = CodeToText[val[2]];
      this.form.codeList = [val[0], val[1], val[2]];
    },

    // 树形节点选中状态
    handleNodeClick(data) {
      let res = this.$refs.tree.getCheckedNodes();
      //  console.log(res);
      let listData = [];
      for (let i = 0; i < res.length; i++) {
        let obj = {};
        obj.categoryCode = res[i].categoryCode;
        obj.categoryName = res[i].categoryName;
        obj.parentCode = res[i].parentCode;
        obj.parentName = res[i].parentName;
        listData.push(obj);
      }
      this.form.relationList = listData;
      // console.log(this.form.relationList);
    },

    // 树形产品分类
    getProduct() {
      var that = this;
      that.$http
        .post("/productCategory/queryTreeList", that.form)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.data = response.data.data;
            // that.$notify.success({
            //   title: "提示",
            //   message: "获取数据成功",
            //   showClose: true,
            // });
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // switch是否禁用
    switchChange(row) {
      console.log(row);
      var that = this;
      that.$http
        .post("/member/updateDisableStatus", { memberCode: row.memberCode })
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            that.showDialog = false;
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },

    // 导出
    async exportExcel() {
      const res = await this.$http.post(
        "/excel/exportMemberRecord",
        this.queryInfo
      );
      if (res.data.code == 200) {
        window.open(res.data.data);
        setTimeout(() => {
          this.$message.success("导出成功！");
        }, 3000);
      } else {
        this.$message.error(res.data.message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
#map {
  height: 180px;
  width: 750px;
}

::v-deep.el-table .cell.el-tooltip {
  display: flex;
  align-items: center;
}

/deep/ .el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100%;
}

.memberInfoItem {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0 15px;
}
</style>
